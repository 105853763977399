import React from 'react';
import styled from 'styled-components';

const Root = styled.div` 
    width: 2.5rem;
    height: 2.3rem;
    position: relative;
    margin: 0.75rem 0;
    transform: rotate(0deg);
    transition: .5s ease-in-out;
    cursor: pointer;

    @media (min-width: 1100px) {
        display: none;
    }

    span {
        display: block;
        position: absolute;
        height: 0.3rem;
        width: 100%;
        background: #c5c6c6;
        border-radius: 0.3rem;
        opacity: 1;
        left: 0;
        transform: rotate(0deg);
        transition: .25s ease-in-out;
    }

    span:nth-of-type(1) {
        top: 0;
    }

    span:nth-of-type(2), span:nth-of-type(3) {
        top: 1rem;
    }

    span:nth-of-type(4) {
        top: 2rem;
    }

    &.open span:nth-of-type(1) {
        top: 1rem;
        width: 0%;
        left: 50%;
    }

    &.open span:nth-of-type(2) {
        transform: rotate(45deg);
    }

    &.open span:nth-of-type(3) {
        transform: rotate(-45deg);
    }

    &.open span:nth-of-type(4) {
        top: 1rem;
        width: 0%;
        left: 50%;
    }
`;

const MenuButton = ({ open, onClick }) => {
    return (
        <Root onClick={onClick} className={open ? 'open' : ''}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </Root>
    );
};

export default MenuButton;
