import { Link } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import MenuButton from "./ui/menuButton"
import BeyondAttendanceTextLogoImage from "../images/beyond-attendance-text-logo.png"
import { useSpring, animated } from "react-spring"
import { useMeasure } from "react-use"
import { OutboundLink } from "gatsby-plugin-gtag"

const Root = styled.header`
  background-color: #18232e;
  width: 100%;
  display: flex;
  justify-content: center;
  height: 4rem;

  @media (max-width: 1100px) {
    height: auto;
  }
`;

const Container = styled.div`
  width: 100%;
  max-width: 1160px;
  display: flex;
  flex-direction: row;
  padding: 0 3rem;

  @media (max-width: 1100px) {
        flex-direction: column;
        padding: 0 1rem;
    }
`;

const NavigationWrapper = styled(animated.div)`
    overflow: hidden;
    width: 100%;

    @media (min-width: 1100px) {
        height: auto !important;
    }
`;

const Navigation = styled(animated.nav)`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 100%;

    @media (max-width: 1100px) {
        justify-content: start;
        height: auto;
    }
`;

const HomeImage = styled.img`
  max-width: 300px;

  @media (max-width: 450px) {
    max-width: 230px;
  }
`;

const HomeImageLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const List = styled(animated.ul)`
    width: 100%;
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0;
    padding: 0 1rem;
    overflow: hidden;
    justify-content: flex-end;

    @media (max-width: 1100px) {
        flex-direction: column;
        padding: 1rem 0;
    }
`;

const ListItem = styled.li`
    display: flex;
    align-items: center;
    float: left;
    margin: 0;

    a {
        background-color: transparent;
        display: block;
        color: #cdcdcd;
        text-align: center;
        padding: 1rem;
        text-decoration: none;
        font-size: 1rem;

        @media (max-width: 1100px) {
          text-align: left;
    }
    }

    a:hover {
        color: #fff;
        text-decoration: underline;
    }

    .button {
      background-color: #d34400;
      color: #fff;
      padding: 0.5rem;
      margin: 0.5rem;
      border-radius: 0.3rem;
      font-weight: 600;
      font-size: 1rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;
      
      
      :hover {
        background-color: #ff5200;
        cursor: pointer;
        text-decoration: none;
      }
    }

    .outline {
      color: #cdcdcd;
      padding: calc(0.5rem - 1px);
      margin: 0.5rem;
      border-radius: 0.3rem;
      border-style: solid;
      border-width: 1px;
      border-color: #cdcdcd;
      font-weight: 400;
      font-size: 1rem;
      text-decoration: none;
      display: flex;
      align-items: center;
      justify-content: center;

        :hover {
          background-color: #ed4c00;
          cursor: pointer;
          text-decoration: none;
        }
    }
`;

const LogoButtonWrapper = styled.div`
    display: flex;
    align-items: center;

    @media (max-width: 1100px) {
        width: 100%;
    }
`;

const Spacer = styled.div`
    flex: 1;
`;

const activeStyle = {
  color: "#fff",
  fontWeight: "bold"
}

const Header = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [ref, { height }] = useMeasure();

  const spring = useSpring({
    config: { tension: 350, friction: 30, clamp: !openMenu },
    height: openMenu ? `${height}px` : '0px'
  });

  return (
    <Root>
      <Container>
        <LogoButtonWrapper>
          <HomeImageLink to="/">
            <HomeImage alt="Beyond Attendance" src={BeyondAttendanceTextLogoImage} />
          </HomeImageLink>
          <Spacer />
          <MenuButton open={openMenu} onClick={() => setOpenMenu(!openMenu)} />
        </LogoButtonWrapper>
        <NavigationWrapper style={spring} className={openMenu ? 'open' : ''}>
          <Navigation ref={ref}>
            <List>
              <ListItem>
                <Link activeStyle={activeStyle} to="/">Home</Link>
              </ListItem>
              <ListItem>
                <Link activeStyle={activeStyle} to="/manufacturing">Manufacturing</Link>
              </ListItem>
              <ListItem>
                <Link activeStyle={activeStyle} to="/retail">Retail</Link>
              </ListItem>
              <ListItem>
                <Link activeStyle={activeStyle} to="/education">Education</Link>
              </ListItem>
              <ListItem>
                <Link activeStyle={activeStyle} to="/construction">Construction</Link>
              </ListItem>
              <ListItem>
                <Link activeStyle={activeStyle} to="/corporate">Corporate</Link>
              </ListItem>
              <ListItem>
                <OutboundLink className="outline" href="https://app.beyondattendance.com/#!/account/signin" rel="noreferrer">Sign In</OutboundLink>
              </ListItem>
              <ListItem>
                <Link className="button" to="/request-demo">Request Demo</Link>
              </ListItem>
            </List>
          </Navigation>
        </NavigationWrapper>
      </Container>
    </Root>
  )

}

export default Header
