import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { Mail, MessageCircle, Phone } from "react-feather"
import { OutboundLink } from "gatsby-plugin-gtag"

const Root = styled.footer`
    background-color: #18232e;
    color: #cdcdcd;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
`;

const Container = styled.div`
    width: 100%;
    max-width: 1160px;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
    margin-top: 4rem;
    padding: 0 1rem;
    box-sizing: border-box;

    @media (max-width: 1100px) {
        margin-top: 1rem;
    }
`;

const ContainerSection = styled.div`
    width: 25%;

    @media (max-width: 900px) {
        width: 50%;
    }

    @media (max-width: 500px) {
        width: 100%;
    }
`
const ContainerSectionHeading = styled.h3`
    text-transform: uppercase;
    color: #fff;
    font-size: 1em;

    @media (max-width: 500px) {
        text-align: center;
    }
`

const List = styled.ul`
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
`;

const ListItem = styled.li`
    float: left;
    margin: 0;
    color: #8e8e8e;
    font-size: 0.9rem;
    display: flex;
    align-items: center;

    a {
        color: #8e8e8e;
        text-decoration: none;
        padding: 0.5rem 0;
    }

    a:hover {
        color: #fff;
        text-decoration: underline;
    }

    @media (max-width: 500px) {
        margin: 0.5rem 0;
        text-align: center;
        justify-content: center;
    }
`;

const Copyright = styled.p`
    font-size: 0.8rem;
    color: #8e8e8e;
    margin-top: 3rem;
    margin-bottom: 1rem;
`;

const activeStyle = {
    color: "#fff",
    fontWeight: "bold"
}

const Footer = () => {
    return (
        <Root>
            <Container>
                <ContainerSection>
                    <ContainerSectionHeading>Resources</ContainerSectionHeading>
                    <List>
                        <ListItem><OutboundLink href="https://beyondattendance.atlassian.net/servicedesk/customer/portals" target="_blank" rel="noreferrer">Help Center</OutboundLink></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/pricing">Price Calculator</Link></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/hardware">Hardware</Link></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/documents">Documents</Link></ListItem>
                    </List>
                </ContainerSection>
                <ContainerSection>
                    <ContainerSectionHeading>Company</ContainerSectionHeading>
                    <List>
                        <ListItem><Link activeStyle={activeStyle} to="/about-us">About Us</Link></ListItem>
                        <ListItem><OutboundLink href="https://www.facebook.com/BeyondAttendance.co.za/" target="_blank" rel="noreferrer">Facebook</OutboundLink></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/referrals">Referrals</Link></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/resellers">Resellers</Link></ListItem>
                    </List>
                </ContainerSection>
                <ContainerSection>
                    <ContainerSectionHeading>Links</ContainerSectionHeading>
                    <List>
                        <ListItem><Link activeStyle={activeStyle} to="/privacy-policy">Privacy Policy</Link></ListItem>
                        <ListItem><Link activeStyle={activeStyle} to="/terms-and-conditions">Terms and Conditions</Link></ListItem>
                        {/*<ListItem><Link activeStyle={activeStyle} to="/sign-up">Sign Up</Link></ListItem>*/}
                        <ListItem><OutboundLink href="https://app.beyondattendance.com/#!/account/signin" rel="noreferrer">Sign In</OutboundLink></ListItem>
                        {/*<ListItem><Link to="/subscriber-agreement">Subscriber Agreement</Link></ListItem>*/}
                    </List>
                </ContainerSection>
                <ContainerSection>
                    <ContainerSectionHeading>Contact Us</ContainerSectionHeading>
                    <List>
                        <ListItem><MessageCircle size="1rem" />&nbsp;<Link activeStyle={activeStyle} to="/contact-us">Send Message</Link></ListItem>
                        <ListItem><Mail size="1rem" />&nbsp;<a href="mailto:sales@beyondattendance.com">sales@beyondattendance.com</a></ListItem>
                        <ListItem><Mail size="1rem" />&nbsp;<a href="mailto:support@beyondattendance.com">support@beyondattendance.com</a></ListItem>
                        <ListItem><Phone size="1rem" />&nbsp;<a href="tel:+27317648550">+27 (0)31 764 8550</a></ListItem>
                    </List>
                </ContainerSection>
            </Container>
            <Copyright>Copyright 2024 Beyond Attendance (Pty) Ltd</Copyright>
        </Root>
    )
}

export default Footer

